import { createUseStyles } from 'react-jss';
import { Theme as WrenchTheme } from '@wds/styles';

const cmsTheme = {
  brandColors: {
    accent: {
      bengal: '#F05B24',
      bumblebee: '#F9BE00',
      raspberry: '#AC145A',
    },
    background: {
      dark1: '#333E48', // wrench's theme.palette.background.dark
      dark2: '#05467E', // midnight
      dark3: '#671E75', // concord
      light1: '#F7F8FA', // wrench's theme.palette.background.main
      light2: '#FFFFFF', // wrench's theme.palette.background.light
    },
    neutral: {
      black: '#000000',
      canvas: '#F7F8FA',
      smoke: '#F7F7F7',
      charcoal: '#333E48',
      flint: '#D0D2D3',
      slate: '#6C777D',
      stone: '#9DA5AA',
      white: '#FFFFFF',
      offWhite: '#EDEEEE',
    },
    primary: {
      arctic: '#2DCCD3',
      concord: '#671E75',
      midnight: '#05467E',
      sabaeus: '#00BF6F',
      vine: '#224F3C',
      heritageGreen: '#008323',
      cleanSlate: '#404040',
      info: '#007FAA',
    },
  },
  endpageColors: {
    embeddedNonBranded: {
      backgroundColor: '#efefef',
    },
    onHover: {
      lighterBumblebee: '#FFCD2D',
      transparentWhite: 'rgba(255, 255, 255, 0.9)',
    },
  },
  util: {
    fullHeight: '100vh',
  },
};
export default cmsTheme;
export type CmsTheme = typeof cmsTheme;
export type CmsWrenchTheme = CmsTheme & WrenchTheme;

export const useGlobalStyles = createUseStyles((theme: CmsWrenchTheme) => ({
  '@global': {
    body: {
      fontFamily: theme.type.fontFamily.base,
    },
  },
}));
