import React from 'react';
import { Box } from '@wds/box';
import { Grid } from '@wds/grid';
import { Typography } from '@wds/typography';
import { createURL } from '@sm/utils';
import { generateMetricsAttribute } from '@sm/metrics';
import { ENDPAGE_EVENTS } from '~app/components/amplitudeEnums';

import { Button as CoreButton } from '../core/Button';
import useStyles from './useStyles';

export type Props = {
  utSource: string;
  utSource2: string;
  templateType: string;
  surveyId: string;
  collectorId: string;
  respondentId: string;
  bannerText: string;
};

const ThirdPartySignUp = ({
  utSource,
  utSource2,
  templateType,
  surveyId,
  collectorId,
  respondentId,
  bannerText,
}: Props): React.ReactElement => {
  const { hardRule, googleButton, appleButton } = useStyles({ isEnterpriseThankYou: false });
  const actionType = 'click-url';
  const actionFlow = 'survey-endpage';
  const utSource3 = 'ThirdPartySignUp';
  const ctaApple = 'Apple';
  const ctaGoogle = 'Google';

  return (
    <>
      <Box mb={5} mt={5}>
        <Grid noBleeds>
          <Grid.Item>
            <Box mb={3} mt={3} className={hardRule} />
          </Grid.Item>
          <Grid.Item>
            <Typography align="center" component="div" variant="bodySm" color="darkMuted">
              {bannerText}
            </Typography>
          </Grid.Item>
          <Grid.Item>
            <Box mb={3} mt={3} className={hardRule} />
          </Grid.Item>
        </Grid>
      </Box>
      <Grid noBleeds>
        <Grid.Item xs={6}>
          <CoreButton
            className={appleButton}
            stretched
            href={createURL('/sign-up/social/apple', { 'sm.origin': 'surveyendpage' })}
            data-sm-metrics={generateMetricsAttribute({
              data: {
                amplitudeEvent: ENDPAGE_EVENTS.USER_INTERACTION,
                itemSelected: 'apple',
                templateType,
                surveyId,
                collectorId,
                respondentId,
                actionType,
                actionFlow,
                log: {
                  message: `${utSource}-${utSource2}-${utSource3}-${ctaApple}`,
                },
              },
            })}
          >
            <span>{ctaApple}</span>
          </CoreButton>
        </Grid.Item>
        <Grid.Item xs={6}>
          <CoreButton
            className={googleButton}
            stretched
            href={createURL('/sign-up/social/google', { 'sm.origin': 'surveyendpage' })}
            data-sm-metrics={generateMetricsAttribute({
              data: {
                amplitudeEvent: ENDPAGE_EVENTS.USER_INTERACTION,
                itemSelected: 'google',
                templateType,
                surveyId,
                collectorId,
                respondentId,
                actionType,
                actionFlow,
                log: {
                  message: `${utSource}-${utSource2}-${utSource3}-${ctaGoogle}`,
                },
              },
            })}
          >
            <span>{ctaGoogle}</span>
          </CoreButton>
        </Grid.Item>
      </Grid>
    </>
  );
};

export default ThirdPartySignUp;
