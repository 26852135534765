import { createUseStyles } from 'react-jss';
import { Theme } from '@wds/styles';
import googleLogo from '~static/images/logos/google.png';
import appleLogo from '~static/images/logos/apple.png';
import { getSrc } from '~app/helpers/assetLoading';

type StyleProps = {
  isEnterpriseThankYou?: boolean;
  isExperiment?: boolean;
};

type classNames =
  | 'hardRule'
  | 'ctaFormField'
  | 'ctaLabel'
  | 'visuallyHidden'
  | 'enterpriseThankYouForm'
  | 'appleButton'
  | 'googleButton'
  | 'disclaimerContainer'
  | 'experimentSpan';

const useStyle = createUseStyles<classNames, StyleProps, Theme>(theme => ({
  ctaFormField: ({ isEnterpriseThankYou, isExperiment }: StyleProps): Record<string, unknown> => {
    const inputBase = {
      fontSize: '15px',
      padding: [0, theme.spacing[3]],
    };

    if (isEnterpriseThankYou) {
      return {
        margin: '0 auto',
        padding: [theme.spacing[3], 0, 0, 0],
        width: '224px',
        '@media (max-width: 374px)': {
          height: '62px',
        },
        // The wrench input used does not allow addition of a classname
        '& input': {
          ...inputBase,
          height: '50px',
          '@media (max-width: 374px)': {
            height: '48px',
          },
        },
        '& label > span': {
          color: theme.palette.text.light,
        },
      };
    }

    return {
      margin: 0,
      padding: isExperiment ? [theme.spacing[1], 0, theme.spacing[5]] : [theme.spacing[1], 0, theme.spacing[3]],
      '& input': inputBase,
    };
  },

  experimentSpan: {
    fontSize: theme.type.fontSize.cardTitle,
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: 'inherit',
    },
  },

  ctaLabel: {
    display: 'block',
    boxSizing: 'border-box',
    marginBottom: '4px',
    // The wrench button used does not allow addition of a classname
    '& > span': {
      fontSize: '13px',
      lineHeight: '1.5em',
      fontWeight: 500,
    },
  },

  visuallyHidden: {
    clip: ' rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },

  /** Only applies to `enterpriseThankYou` */
  enterpriseThankYouForm: {
    // The wrench button used does not allow addition of a classname
    '& button': {
      backgroundColor: theme.palette.background.light,
      height: '50px',
      width: '140px',
      margin: [20, 'auto', 0],
      padding: 0,
      color: theme.palette.text.dark,
      border: [1, 'solid', theme.palette.text.dark],
      '&:hover, &:focus': {
        borderColor: theme.palette.border.main,
        backgroundColor: theme.palette.background.light,
      },
      '&:active': {
        borderColor: theme.palette.border.active,
        backgroundColor: theme.palette.background.light,
      },
    },
  },

  hardRule: ({ isEnterpriseThankYou }) => ({
    borderTop: isEnterpriseThankYou ? 'none' : [2, 'solid', theme.palette.border.main],
    width: isEnterpriseThankYou ? 0 : '100%',
  }),

  appleButton: {
    // The wrench button used does not allow addition of a classname
    '& > a': {
      boxSizing: 'border-box',
      fontSize: '15px',
      border: 'none',
      backgroundRepeat: 'no-repeat',
      backgroundSize: theme.spacing[5],
      backgroundColor: theme.palette.background.light,
      backgroundImage: `url(${getSrc(appleLogo)})`,
      color: theme.palette.text.darkMuted,
      backgroundPosition: '10% 41%',
      boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.12), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
      '&:focus, &:hover': {
        backgroundColor: theme.palette.background.main,
        textDecoration: 'none',
      },
      '& > span': {
        marginLeft: theme.spacing[4],
      },
    },
  },

  googleButton: {
    // The wrench button used does not allow addition of a classname
    '& > a': {
      boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.12), 0 0 8px 0 rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      fontSize: '15px',
      border: 'none',
      backgroundRepeat: 'no-repeat',
      backgroundSize: theme.spacing[5],
      backgroundColor: theme.palette.background.light,
      color: theme.palette.text.darkMuted,
      backgroundPosition: '13% 50%',
      backgroundImage: `url(${getSrc(googleLogo)})`,
      '&:focus, &:hover': {
        backgroundColor: theme.palette.background.main,
        textDecoration: 'none',
      },
      '& > span': {
        marginLeft: theme.spacing[4],
      },
    },
  },

  disclaimerContainer: {
    '& a': {
      color: theme.palette.link.main,
    },
  },
}));

export default useStyle;
