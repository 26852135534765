import { useCallback, useMemo, useState } from 'react';

export type ValidationType = 'email' | 'password';

type FieldValidatorHook = {
  data: string;
  error: string;
  hasEnteredText: boolean;
  isLoading: boolean;
  isValid: boolean;
  setText: (this: void, text: string) => void;
};

type SignupValidatorHook = {
  emailError: string;
  hasEnteredEmail: boolean;
  hasEnteredPassword: boolean;
  isLoading: boolean;
  isValid: boolean;
  passwordError: string;
  setEmail: (this: void, email: string) => void;
  setPassword: (this: void, password: string) => void;
};

/**
 * Hook to validate a sign-up field against usersvc' validation API
 *
 * @private
 * @param validationType The type of sign-up field to be validated
 */
export function useUserSvcFieldValidator(validationType: ValidationType): FieldValidatorHook {
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasEnteredText, setHasEnteredText] = useState(false);

  useMemo(() => {
    const fetchValidationResponse = async (): Promise<void> => {
      // don't validate on initial render; we know the inputs are empty
      if (hasEnteredText) {
        setIsLoading(true);
        try {
          // NOTE: as implemented, this currently only validates on an input's blur event, introducing
          // "human reaction time" debouncing. if that should change, we can add a proper debounce here
          const response = await fetch(`/resp/api/validate/${validationType}`, {
            method: 'POST',
            body: JSON.stringify({ data }),
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(async res => res.json());
          setError(response.valid ? '' : response.reason);
        } catch (err: unknown) {
          setError('network_error');
        }
        setIsLoading(false);
      }
    };

    // this and the above function-wrapped guts are to keep the linter from yelling at
    // me about no floating promises. I didn't invent the rules, I just follow them...
    void fetchValidationResponse();
  }, [validationType, data, hasEnteredText, setError, setIsLoading]);

  const setText = useCallback(
    text => {
      setHasEnteredText(true);
      setData(text);
    },
    [setData, setHasEnteredText]
  );

  return {
    data,
    hasEnteredText,
    setText,
    isLoading,
    isValid: !isLoading && !error && hasEnteredText,
    error,
  };
}

/**
 * React hook that validates an email address and password against usersvc for sign-ups
 *
 * NOTE: This uses an old sign-up path that will be replaced by Auth0 in the future.
 *       We're using this as part of the migration from anonweb -> respweb so we can
 *       compare the two services
 */
export default function useSignUpValidation(): SignupValidatorHook {
  const {
    setText: setEmail,
    data: email,
    hasEnteredText: hasEnteredEmail,
    isLoading: isEmailValidating,
    isValid: isEmailValid,
    error: emailError,
  } = useUserSvcFieldValidator('email');
  const {
    setText: setPassword,
    data: password,
    hasEnteredText: hasEnteredPassword,
    isLoading: isPasswordValidating,
    isValid: isPasswordValid,
    error: passwordError,
  } = useUserSvcFieldValidator('password');
  // there is one check that validates the email value against the password and is then presented as a password
  // error, so we'll track that here and use it to override the password error coming from the validation backend
  const [emailPasswordError, setEmailPasswordError] = useState('');
  const isLoading = useMemo(() => isEmailValidating || isPasswordValidating, [isEmailValidating, isPasswordValidating]);
  const isValid = useMemo(
    () => isEmailValid && isPasswordValid && !emailPasswordError,
    [isEmailValid, isPasswordValid, emailPasswordError]
  );

  // ensure the password and email are not the same
  useMemo(() => {
    // no error if the fields are empty
    if (!email || !password) {
      setEmailPasswordError('');
      return;
    }
    setEmailPasswordError(email.toLowerCase() === password.toLowerCase() ? 'email_password_same' : '');
  }, [email, password, setEmailPasswordError]);

  return {
    setEmail,
    setPassword,
    hasEnteredEmail,
    hasEnteredPassword,
    emailError,
    passwordError: passwordError || emailPasswordError,
    isLoading,
    isValid,
  };
}
