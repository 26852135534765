import React from 'react';
import { Button as WrenchButton, ButtonBaseProps as WrenchButtonProps } from '@wds/button';

type HTMLWrapperAttributes<T> = React.AllHTMLAttributes<T> & {
  // Until TS 4.4.x there is no way to specifically define a key-pattern
  // This is to allow data-* attributes to be passed as an object key.
  [dataAttributes: string]: unknown;
};

type AnchorElementProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  WrenchButtonProps & {
    href: string; // Anchor elements must have an `href`
  };

type ButtonElementProps = React.ButtonHTMLAttributes<HTMLButtonElement> & WrenchButtonProps;

export type ButtonProps = (AnchorElementProps | ButtonElementProps) & {
  /** element tag for the container (defaults to 'div') */
  element?: 'div' | 'span';
  /** Classes to be added to the `element` */
  className?: string;
  /** additional props specific to the `element` (trap-door); excludes `className` */
  componentProps?: Omit<HTMLWrapperAttributes<HTMLDivElement | HTMLSpanElement>, 'className'>;
};

/** Type Guard to determine if props belong to an Anchor */
function isAnchorProps(props: ButtonProps): props is AnchorElementProps {
  return 'href' in props;
}

/**
 * @description Generic Stylable Button.
 * This component can be used in place of the `@wds/button` component to
 * modify the styles, until the `@wds/button` version supports className prop
 * passthrough.
 */
export default function Button({
  element: Element = 'div',
  className,
  componentProps,
  ...buttonProps
}: ButtonProps): React.ReactElement {
  const wrenchButtonProps = isAnchorProps({ ...buttonProps })
    ? ({ ...buttonProps } as AnchorElementProps)
    : ({ ...buttonProps } as ButtonElementProps);

  return (
    <Element {...componentProps} className={className}>
      <WrenchButton {...wrenchButtonProps} />
    </Element>
  );
}
